import React from "react"

import Layout from "../../components/layout"
import { Section, Container } from "../../components/anti/grid/grid"
import { Card } from "../../components/anti/card/card"

import Car from "../../assets/img/icons/ic-facilites-parking@2x.png"

const Experiment = () => {
  return (
    <Layout>
      <Section className="bg-light">
        <Container className="mw-lg">
          <div className="row">
            <div className="col-3">
              <Card
                className="card-facilities-category"
                cardBodyClassName="pt-5"
                colLeft="col-12"
                colRight="col-12"
                icon={Car}
                iconWidth="w-20px"
                title="54"
                titleClassName="text-center"
                text="Education"
                textClassName="text-center"
                img="https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                imgHeight="h-ratio-3-2"
                link={`/`}
              />
            </div>
            <div className="col-3">
              <Card
                className="card-facilities-category"
                cardBodyClassName="pt-5"
                colLeft="col-12"
                colRight="col-12"
                icon={Car}
                iconWidth="w-20px"
                title="54"
                titleClassName="text-center"
                text="Education"
                textClassName="text-center"
                img="https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                imgHeight="h-ratio-3-2"
                link={`/`}
              />
            </div>
            <div className="col-3">
              <Card
                className="card-facilities-category"
                cardBodyClassName="pt-5"
                colLeft="col-12"
                colRight="col-12"
                icon={Car}
                iconWidth="w-20px"
                title="54"
                titleClassName="text-center"
                text="Education"
                textClassName="text-center"
                img="https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                imgHeight="h-ratio-3-2"
                link={`/`}
              />
            </div>
            <div className="col-3">
              <Card
                className="card-facilities-category"
                cardBodyClassName="pt-5"
                colLeft="col-12"
                colRight="col-12"
                icon={Car}
                iconWidth="w-20px"
                title="54"
                titleClassName="text-center"
                text="Education"
                textClassName="text-center"
                img="https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                imgHeight="h-ratio-3-2"
                link={`/`}
              />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default Experiment
